<template>
  <div class="evaluating_title_box">
    <h3>
      <span>
        <slot name="title"></slot>
      </span>
    </h3>
    <div class="evaluating_title_item">
      <span>测评记录说明：</span>
      <div class="evaluating_title_item_info">
        <span v-for="(item,index) in count" :key="index">第{{item}}次</span>
      </div>
      <a-button v-if="isShow" @click="addRecord">+添加记录</a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vbStage',
  model: {
    prop: 'second',
    event: 'secondChange'
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    second: {
      type: Number,
      default: 4
    }
  },
  data () {
    return {

    }
  },
  methods: {
    addRecord () {
      // debugger
      if (this.count >= 6) {
        return this.$message.info('最多只能六次测评')
      }
      this.count++
    }
  },
  computed: {
    count: {
      get () {
        return this.second
      },
      set (val) {
        this.$emit('secondChange', val)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.evaluating_title_box h3 {
  font-size: 22px;
  padding: 0 10px;
  text-align: center;
  padding: 36px 0;
  margin: 0;
  >span{border-bottom: 8px solid rgba(255, 124, 36, 0.19); }
}
.evaluating_title_item_info {
  > span {
    margin-left: 36px;
  }
  > span::before {
    content: '';
    width: 14px;
    height: 14px;
    border: 1px solid #fff;
    display: inline-block;
    border-radius: 100%;
    // box-shadow: 0 0 5px 0.5px rgb(0 0 0 / 10%);
    margin-right: 10px;
  }
  > span:nth-child(1)::before {
    background: #FF7237;
  }
  > span:nth-child(2)::before {
    background: #41C73D;
  }
  > span:nth-child(3)::before {
    background: #FFC33B;
  }
  > span:nth-child(4)::before {
    background: #DB85FF;
  }
  > span:nth-child(5)::before {
    background: #55CBF9;
  }
  > span:nth-child(6)::before {
    background: #5863FF;
  }
}
.evaluating_title_item {
  background: #fff5df;
  height: 37px;
  border-radius: 5px;
  line-height: 37px;
  display: flex;
  justify-content: center;
  width: 853px;
  margin: 0 auto;

  ::v-deep .ant-btn {
    background: var(--color-tint);
    color: #fff;
    margin-left: 15px;
    border: none;
  }
}
</style>
