<template>
  <div>
    <div class="evaluating_box_score" v-if="isShowScore">
      <h4>{{totalScore}}</h4>
      <span>评估得分</span>
    </div>
    <div class="evaluating_box_in" style="display: flex;
    flex-direction: column-reverse;">
      <div class="evaluation" v-for="(item,i) in tableForm" :key="i">
        <h2 class="evaluation_item_title">第{{i+1}}阶段</h2>
        <div class="evaluation_item">
          <div class="dimension">
            <div class="score">
              <span
                @click="addEvaluation"
                v-for="(scoreItem,index) in scoreData[i]"
                :key="index"
              >{{scoreItem}}</span>
            </div>
            <div class="dimensionBox">
              <div class="dimensionBox_in" v-for="(item2,j) in item" :key="j">
                <template v-if="isEdit">

                  <div class="color_btn" :class="second<=4?'color_btn4':'color_btn6'">
                    <template v-if="showCircle(i,item2.name_id)">
                      <i
                        v-for="(itemNum,indey) in second"
                        :key="indey"
                        :class="{cur:getCircleCls(indey,item2)}"
                        @click="chooseCircle(item2)"
                      ></i>
                    </template>
                  </div>
                </template>
                <div class="color_btn color_btn6" v-else>
                  <span>{{item2.name}}</span>
                </div>
                <div class="dimensionBox_in_item">
                  <span
                    v-for="(itemSub,idx) in item2.option"
                    :key="idx"
                    @click="addScore(item2,idx,i)"
                    :style="{background:itemSub.color}"
                  ></span>
                </div>
                <strong v-if="isEdit">{{item2.name}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vbEvaluationRecord',
  model: {
    prop: 'tableForm',
    event: 'tableFormChage'
  },
  props: {
    tableForm: {
      type: Array,
      default () {
        return []
      }
    },
    isShowScore: {
      type: Boolean,
      default: true
    },
    num: {
      type: Number,
      default: 1
    },
    second: {
      type: Number,
      default: 4
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    totalScore () {
      const stages = this.tableForm
      var score = stages.flatMap(stage => stage.flatMap(item => item.option)
      ).reduce((total, item) => {
        return total + item.value
      }, 0)
      return score
    }
  },
  data () {
    return {
      twoStageShowNameIds: ['lrffc', 'talk', 'team', 'structure'],
      thereStageShowNameIds: ['read', 'write', 'mathema'],
      colors: ['#FF7237', '#41C73D', '#FFC33B', '#DB85FF', '#55CBF9', '#5863FF'],
      backgroundColor: '',
      scoreData: {
        0: ['1M', '2M', '3M', '4M', '5M'],
        1: ['6M', '7M', '8M', '9M', '10M'],
        2: ['11M', '12M', '13M', '14M', '15M']
      },
      isDone: false,
      tableFormCopy: [],
      isBtnShow: true,
      circleClickMap: { require: false, nominate: false, reaction: false, pair: false, game: false, contact: false, imitate: false, echolalia: false, sound: false, read: false, write: false, lrffc: false, talk: false, team: false, structure: false, mathema: false }
    }
  },
  watch: {
    tableForm: {
      handler (newVal) {
        this.$emit('tableFormChage', newVal)
        if (!this.isDone) {
          this.tableFormCopy = this.deepClone(newVal)
          this.isDone = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
  },
  methods: {
    deepClone (obj) {
      // 定义一个变量 并判断是数组还是对象
      var objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object' && obj != null) {
        // 判断obj存在并且是对象类型的时候 因为null也是object类型，所以要单独做判断
        for (var key in obj) {
          // 循环对象类型的obj
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // 判断obj中是否存在key属性
            if (obj[key] && typeof obj[key] === 'object') {
              // 判断如果obj[key]存在并且obj[key]是对象类型的时候应该深拷贝，即在堆内存中开辟新的内存
              objClone[key] = this.deepClone(obj[key])
            } else {
              // 否则就是浅复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    },
    getOptionsByNameId (nameId) {
      const stages = this.tableForm
      const options = []
      stages.forEach((stage, key, array) => {
        stage.forEach((item, key, array) => {
          if (item.name_id === nameId) {
            options.push(...item.option)
          }
        })
      })
      return options
    },
    getIndexByNameId (nameId, cellIndex, stageIndex) {
      let preStageCount = 0
      for (let i = 0; i < stageIndex; i++) {
        const stage = this.tableForm[i]
        const result = stage.some(item => item.name_id === nameId)
        if (result) {
          preStageCount++
        }
      }
      return preStageCount * 10 + cellIndex
    },
    showCircle (i, nameId) {
      if (i === 0) {
        return true
      }
      if (i === 1 && this.twoStageShowNameIds.indexOf(nameId) !== -1) {
        return true
      }

      if (i === 2 && this.thereStageShowNameIds.indexOf(nameId) !== -1) {
        return true
      }
      return false
    },
    chooseCircle (info) {
      // debugger
      if (!this.isEdit) {
        return false
      }
      this.tableForm.forEach((item, key, array) => {
        item.forEach((item, key, array) => {
          if (item.name_id === info.name_id) {
            item.choose = 1
            item.submitInfo = this.updateSubmitInfo(item.submitInfo, this.num - 1, 1)
          }
        })
      })
      this.circleClickMap[info.name_id] = true
      this.$forceUpdate()
    },
    updateSubmitInfo (origin, index, val) {
      return origin.substr(0, index) + val + origin.substr(index + 1, origin.length)
    },
    reversed (nameId, stageIndex) {
      let count = 0
      for (let i = 0; i < 3; i++) {
        const stage = this.tableForm[i]
        const item = stage.find(t => t.name_id === nameId)
        if (item && item.option.length) {
          for (let j = 0; j < item.option.length; j++) {
            const cell = item.option[j]
            if (count !== 2) {
              if (cell.value === 0) {
                count++
              } else {
                count = 0
              }
            } else {
              cell.value = 0
              cell.key = 0
              cell.color = ''
            }
          }
        }
      }
    },
    getCircleCls (index, item) {
      if (index + 1 < this.num) {
        // console.log(item.submitInfo)
        const val = item.submitInfo.substr(index, 1)
        // console.log('val', val)
        if (val > 0) {
          return true
        }
      }
      if (index + 1 === this.num && item.choose && this.circleClickMap[item.name_id]) {
        return true
      }
      return false
    },
    addScore (item, cellIndex, stageIndex) {
      // debugger
      // 判断是查询不可修改
      if (!this.isEdit) {
        return false
      }
      // 判断是否可以操作
      if (!item.choose || !this.circleClickMap[item.name_id]) {
        return false
      }
      // debugger
      // 判断是否当次权限提交
      const curOps = item.option
      const curCell = curOps[cellIndex]
      // const PreCell = curOps[cellIndex - 1]

      // 2024/06/07 需求修改 前边的格子改为可以点击
      // if (curCell.key !== this.num && curCell.key > 0) {
      //   return false
      // }
      if (curCell.key !== this.num && curCell.value > 0) {
        return false
      }

      // if (stageIndex === 0) {
      //   var a = true
      //   this.tableForm[stageIndex].forEach(item => {
      //     if (item.option[stageIndex].value === 0 && curOps[cellIndex].value === 0) {
      //       a = false
      //     }
      //   })
      //   return a
      // }

      // 反选
      if (curCell.value > 0) {
        const oldMain = this.tableFormCopy[stageIndex].find(e => e.name_id === item.name_id)
        if (oldMain) {
          const oldCell = oldMain.option[cellIndex]
          curCell.value = oldCell.value
          curCell.key = oldCell.key
          curCell.color = oldCell.color
        }
        // curCell.value = 0
        // curCell.key = 0
        // curCell.color = ''
        this.reversed(item.name_id, stageIndex)
        this.$forceUpdate()
        return false
      }

      const options = this.getOptionsByNameId(item.name_id)
      const index = this.getIndexByNameId(item.name_id, cellIndex, stageIndex)
      if (index > 0) {
        const first = options[0]
        if (first.value === 0) {
          return false
        }
      }

      if (index >= 2) {
        const pre = options[index - 1]
        const preParent = options[index - 2]
        if (pre.value === 0 && preParent.value === 0) {
          return false
        }
      }

      curCell.value = 0.5
      curCell.key = this.num
      curCell.color = this.colors[this.num - 1]
    },
    addEvaluation () {

    },
    reverse (arr, phaseIndex) {
      return arr.sort((a, b) => {
        var curSort = this.pharseSorts[phaseIndex]
        return curSort[a.name_id] - curSort[b.name_id]
      })
    }
  }
}
</script>
<style lang="less" scoped>
.evaluating_box_in {
  width: 990px;
  margin: 0 auto;
  padding-top: 28px;
}
.evaluation {
  overflow: hidden;
  padding-bottom: 66px;
}
.evaluation_item_title {
  border-bottom: 2px solid var(--color-tint);
  display: inline-block;
  padding: 0 6px 3px;
  font-size: 20px;
}
.score {
  border-right: 1px solid #707070;
  display: flex;
  flex-direction: column-reverse;
  width: 38px;
  float: left;
  height: 242px;
  span {
    padding-bottom: 21px;
    cursor: pointer;
  }
}
.dimensionBox {
  float: left;
  border-bottom: 1px solid #707070;
  height: 242px;
  padding-left: 13px;
  padding-right: 40px;
  .dimensionBox_in {
    height: 100%;
    width: 56px;
    float: left;
    margin-right: 13px;
    position: relative;

    display: flex;
    flex-direction: column-reverse;
    .dimensionBox_in_item {
      display: flex;
      flex-direction: column-reverse;
      span {
        width: 60px;
        height: 17px;
        background: #cbcbcb;
        float: left;
        margin-top: 2px;
      }
      > span:nth-child(2n) {
        margin-top: 7px;
      }
    }
    > strong {
      font-weight: normal;
      text-align: center;
    }
  }
  .color_btn {
    position: absolute;
    bottom: -17px;

    width: 60px;
    > i {
      border: 1px solid #707070;
      width: 12px;
      display: inline-block;
      height: 12px;
      border-radius: 100%;
    }
  }
  .color_btn4 {
    display: flex;
    justify-content: space-between;
  }
  .color_btn6 {
    bottom: -30px;
    > i {
      margin: 1px 4px 0;
      float: left;
    }
  }
}
.evaluating_box {
  box-shadow: 0 0 5px 0.5px rgb(0 0 0 / 10%);
  border-radius: 30px;
  margin-bottom: 264px;
}
.evaluating_box_score {
  width: 171px;
  height: 126px;
  background: url('../../../assets/images/score_img.png') no-repeat;
  text-align: center;
  margin: 0 auto;
  margin-top: 33px;
  > h4 {
    font-size: 30px;
    color: #6a3700;
    padding-top: 36px;
    text-align: center;
    margin-bottom: 14px;
  }
  > span {
    font-size: 16px;
    color: #fff;
  }
}
.color_btn {
  i.cur:nth-child(1) {
    background: #FF7237;
  }
  i.cur:nth-child(2) {
    background: #41C73D;
  }
  i.cur:nth-child(3) {
    background: #FFC33B;
  }
  i.cur:nth-child(4) {
    background: #DB85FF;
  }
  i.cur:nth-child(5) {
    background: #55CBF9;
  }
  i.cur:nth-child(6) {
    background: #5863FF;
  }
}
.color_btn6 > span {
  width: 60px;
  text-align: center;
  display: inline-block;
}
</style>
